

import { ModelSelect, ModelListSelect  } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'


import DATA_MASTER from  '../../library/dataMaster'
import FETCHING from '../../library/fetching'
import UMUM from "../../library/umum.js";
  

export default {
   components: {
      ModelSelect, ModelListSelect, 
  },
  data() {
    return {
        list_instansi:[],
        list_unitkerja:[],
        cariOPD:'',
        formlist_opd:[],

         tahun: [
				      { id: '', tahun: 'Pilih Tahun'},
				     
				      { id: 2023, tahun: '2023'},
				      { id: 2024, tahun: '2024'},
				      { id: 2025, tahun: '2025'},
		    ],

        irban : [
           { id: '', irban: 'Pilih Irban'},
				   { id: 5, irban: 'IRBAN I'},
				   { id: 6, irban: 'IRBAN II'},
				   { id: 7, irban: 'IRBAN III'},
				   { id: 8, irban: 'IRBAN IV'},
				   { id: 9, irban: 'IRBAN INVESTIGASI'},
        ],

        filter : {
          irban :'' 
        },

       
        
      list_hasil:[],
      lokasi : [],
      form : {
          id              : '',
          uraian          : '',
          keterangan     : '',
          daritgl        : '',
          sampaitgl      : '',
          instansi_id    : '',
          unit_kerja_id  : '',
          master_type_id : 1,
          menu_klp : '',
          list_opd       :[],
        },

         tanggapan : {
          id : '',
          direktori_id : '',
          unit_kerja_id : '',
          uraian : '',
          keterangan : '',
          file : null,
          file_type : '',
        },


        lampiran : {
          id : '',
          direktori_id : '',
          unit_kerja_id : '',
          master_type_id : 1,
          uraian : '',
          keterangan : '',
          file : null,
          file_type : '',
          status_wali : 0,
          status_opd : 0,
          notice : '',
           file_old : null,
        },

        filter : {
          tipe : '',
        },
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      tipe_akses : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      
      autocomplete_db : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      
      dummyObj : null,
      list_data : [],
      list_lampiran : [],
      list_cetak : [],
      list_opd : [],
      list_direktori_opd : [],
      

      tipe: 0,

      val:[],
      
      file_path: this.$store.state.url.URL_APP + "uploads/",

      cek_load_data : true,


       mdl_add : false,
        mdl_edit : false,
        mdl_delete : false,
        mdl_hapus : false,
        mdl_list : false,
        mdl_add_file : false,
        mdl_edit_file : false,
        mdl_view_file : false,
        mdl_keterangan : false,
        mdl_add_opd : false,
        mdl_open: false,
        mdl_cetak: false,
        mdl_list_direktori: false,
        mdl_hasil_file: false,
        
        mdl_list_hasil : false,
        mdl_add_tanggapan : false,

        checkAll : true,
        tahundir : 0,
        total_file : 0,
        nama_direktori : '',
        nama_keterangan : '',


        
        instansi_id : '',
        unit_kerja : '',
        

        cthSelect : '',

        page_first: 1,
        page_last: 0,
        page_limit : 10,
        cari_value: "",
        FETCHING : FETCHING,
        UMUM : UMUM,
        
      DATA_MASTER : DATA_MASTER, 
        fileOld : null,


        hasilaudit: "",

    }
  },
  methods: {

    print : function(){
      const element = document.getElementById("printsaja");
      window.print()
    },

    getcheckAll : function () {

        console.log("===========");
          // this.lokasi = this.$store.state.listOPD

        if (this.checkAll==true) {
          
          this.lokasi = this.$store.state.listOPD
          this.checkAll = false
        } else {
          this.lokasi = []
          this.checkAll = true
          
        }

    },


    getHasilaudit : function(){
      fetch(this.$store.state.url.URL_filereviu + "hasilaudit", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
               
                unit_kerja : this.lampiran.unit_kerja_id,
                direktori_id : this.lampiran.direktori_id,
            })
        })
          .then(res => res.json())
          .then(res_data => {
              this.hasilaudit = res_data.data[0].file;
              console.log("sfsdf ", this.hasilaudit);
              
      });
    },

    
    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_direktorireviu + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                page_limit : this.page_limit,
                unit_kerja_id : this.form.unit_kerja_id,
                master_type_id : this.form.master_type_id,
                menu_klp : this.form.menu_klp,
                instansi_id : this.instansi_id,
                unit_kerja : this.unit_kerja_id,
                direktori_id : this.id_direktori,
                tipe : this.tipe,
                irban : this.filter.irban
            })
        })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },
    addData : function() {
        // console.log("ini lokasi : ", this.lokasi);
        
        fetch(this.$store.state.url.URL_direktorireviu + "addData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
            form : this.form,
            lokasi:this.lokasi
          })
            
        }).then(res_data => {
            this.getView();
           this.Notify ('Sukses Menambah Data', 'primary', 'check_circle_outline');
        });


      },


    editData : function(){
      fetch(this.$store.state.url.URL_direktorireviu + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            form : this.form
          })
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

  
    removeData : function(E){
      fetch(this.$store.state.url.URL_direktorireviu + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },

    lokasiData : function(id){
      
          fetch(this.$store.state.url.URL_direktori + "lokasi", {
              method: "POST",
              headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
              },
              body: JSON.stringify({
                  
                  id_direktori : id
              })
          })
            .then(res => res.json())
            .then(res_data => {
                this.lokasi = res_data.data;
                
                this.$store.commit("hideLoading");
                
        });
    },


    getTanggapan : function(dir, unit){
   
        fetch(this.$store.state.url.URL_filereviu + "tanggapan", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                direktori_id : dir,
                unit_kerja_id : unit,

            })
        })
            .then(res => res.json())
            .then(res_data => {
              console.log("AAAAAAAAAAAA " + res_data.data)
              this.list_hasil = res_data.data;
        });
      },

   selectData : function(data){

          this.form.id= data.id;
          this.form.uraian = data.uraian;
          this.form.keterangan = data.keterangan;
          this.form.daritgl = data.daritgl;
          this.form.sampaitgl = data.sampaitgl;
          this.form.unit_kerja_id = data.unit_kerja_id;
          this.form.master_type_id= data.master_type_id;
          this.form.menu_klp= data.menu_klp;


          // this.lampiran.direktori_id = data.id;
          // this.lampiran.unit_kerja_id = data.unit_kerja_id;

          // this.getLampiran();

      },

      
       selectLampiran : function(data){

          this.lampiran.id = data.id;
          this.lampiran.direktori_id = data.direktori_id;
          this.lampiran.unit_kerja_id = data.unit_kerja_id;
          this.lampiran.master_type_id = data.master_type_id;
          this.lampiran.uraian = data.uraian;
          this.lampiran.keterangan = data.keterangan;
          this.lampiran.status_wali = data.status_wali;
          this.lampiran.status_opd = data.status_opd;
          this.lampiran.notice = data.notice;
        this.lampiran.file_old =  data.file
        
        this.lampiran.file = this.lampiran.file
          
          this.form.unit_kerja_id = data.unit_kerja_id;
       
       

      },

      getLampiran : function(dir, unit){
   
        fetch(this.$store.state.url.URL_filereviu + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                direktori_id : dir,
                unit_kerja_id : unit,
                unit_kerja : this.unit_kerja_id,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              console.log(res_data)
              this.list_lampiran = res_data
        });
      },

      addLampiran: function(){
 
        var get_profile = JSON.parse(localStorage.profile);
        this.lampiran.unit_kerja_id = get_profile.profile.unit_kerja; 

        var formData = new FormData();
        formData.append('pb', JSON.stringify(this.lampiran));
        formData.append('file', this.lampiran.file);

        fetch(this.$store.state.url.URL_filereviu + 'addData', {
            method: "POST",
            headers: {
                authorization: "kikensbatara " + localStorage.token
            },
            body: formData
            }).then(res_data => {
              
              // this.lampiran.direktori_id
               this.Notify ('Sukses Menambah Data', 'primary', 'check_circle_outline');
              var a = this.lampiran.direktori_id
              var b = this.lampiran.unit_kerja_id 
              this.getLampiran(a, b); 
              this.getView1(data.id);
        });
      },

      editLampiran: function(){

        var formData = new FormData();
        formData.append('pb', JSON.stringify(this.lampiran));
        formData.append('file', this.lampiran.file);
        // formData.append('fileOld', this.fileOld);

        fetch(this.$store.state.url.URL_filereviu + 'editData', {
            method: "POST",
            headers: {
                authorization: "kikensbatara " + localStorage.token
            },
            body: formData
            }).then(res_data => {
              var a = this.lampiran.direktori_id
              var b = this.lampiran.unit_kerja_id 
              this.getLampiran(a, b); 
              this.$store.commit('notifAdd', 'Menambah')
        });
      },

      removeLampiran : function(E){
      fetch(this.$store.state.url.URL_filereviu + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.lampiran.id})
      }).then(res_data => {
        var a = this.lampiran.direktori_id
        var b = this.lampiran.unit_kerja_id 
        this.getLampiran(a, b); 
        this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
        
      });

    },

    

    getView1 : function(a){

      var get_profile = JSON.parse(localStorage.profile);
      var level_users = get_profile.profile.trista_monev;
      console.log(level_users);
        
        fetch(this.$store.state.url.URL_direktorireviu + "view1", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                id_direktori : a,
                unit_kerja_id : this.form.unit_kerja_id,
                
                unit_kerja : this.unit_kerja_id,
                level_users:level_users,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              this.list_direktori_opd = res_data;

              this.nama_direktori = this.list_direktori_opd[0].uraian;
              this.tahundir = this.list_direktori_opd[0].tahun;
              this.total_file = this.list_direktori_opd.map(total => total.proses).reduce((acc, amount) => acc + amount);
              this.nama_keterangan = this.list_direktori_opd[0].keterangan;
              console.log(this.nama_keterangan);
        });
      },
      

      select1(data){
        // console.log(data);
        // console.log(data.id);data.id

        this.lampiran.direktori_id = data.id
        this.getView1(data.id)


         

        // this.lampiran.direktori_id = data.id
        // this.lampiran.unit_kerja_id
        

      },
      select2(data){
        console.log(data.id);
        console.log(data.id_instansi);
        this.lampiran.direktori_id = data.id
        this.lampiran.unit_kerja_id = data.id_instansi


        this.getLampiran(data.id, data.id_instansi);
        
        this.getTanggapan(data.id, data.id_instansi) 
        
      },

      hapusArray : function(data){
      var arr = this.lokasi
      const objWithIdIndex = arr.findIndex((obj) => obj.uniqid === data.uniqid);
      console.log(objWithIdIndex);
      this.lokasi.splice(objWithIdIndex, 1);
    },

    

    


    // ====================================== CONTOH eDOC ====================================
    onClickx(data){
      alert(data)
    },
    // ====================================== CONTOH eDOC ====================================

  // ====================================== CONTOH AUTOCOMPLETE ====================================
    tipe_akses_filter : function (val, update) {
        update(() => {
          if (val === '') {}
          else {FETCHING.getType(val)}
        })
    },

    // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db_filter : function (val, update) {
        update(() => {
          if (val === '') {}
          else {FETCHING.getContohAtocomplete(val)}
        })
    },
    // ====================================== CONTOH AUTOCOMPLETE ====================================








    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },

         iniopd : async function(){
          this.$store.state.listOPD = await FETCHING.FETCH_POST(this.$store.state.url.URL_simpeg_unit_kerja + "listreviu", {cari : this.cariOPD})
        },
          funcAwaitAdd : async function(){

        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {
    
      var get_profile = JSON.parse(localStorage.profile);
      this.form.instansi_id = get_profile.profile.instansi_id;
      this.form.unit_kerja_id = get_profile.profile.unit_kerja;
      this.form.menu_klp = get_profile.profile.trista_monev;
      this.lampiran.unit_kerja_id = get_profile.profile.unit_kerja;
      this.instansi_id = get_profile.profile.instansi_id;
      this.unit_kerja_id = get_profile.profile.unit_kerja;

      this.tipe = get_profile.profile.trista_monev;
      this.filter.tipe = get_profile.profile.trista_monev;

      this.getView();
    FETCHING.getContohAtocomplete('');
    FETCHING.getType('');
    
    this.iniopd();
    // this.getView();
  },
}
